<!--
 * @Author: John
 * @Date: 2022-04-02 16:01:49
 * @LastEditTime: 2022-04-19 19:57:30
 * @LastEditors: John
 * @Description: 选择基金
 * @FilePath: \jjms_ui\src\components\jj-select.vue
 * @Copyright John
-->
<template>
    <el-form-item :label="label" :prop="prop">
        <el-select v-model="value" clearable placeholder="请选择" @change="$emit('input', selectObj)">
            <el-option
            v-for="item in options"
            :key="item.jjValue"
            :label="item.jjName"
            :value="item.jjCode"
            >
            </el-option>
        </el-select>
    </el-form-item>
</template>
<script>
import api from '@/api/req_api.js'

export default {
    name: 'jj-select',
    data() {
        return {
            options: null,
            value: ''
        }
    },
    props: {
        label: {
            type: String,
            default: '基金名称'
        },
		prop: {
			type: String,
            default: 'jjCode'
		},
        initValue: {
            type: Object,
            default: null
        }
    },
    mounted() {
        api.queryDtList()
            .then(data => {
                if(data != null) {
                    this.options = data;
                }
            })
    },
    computed: {
        selectObj() {
            // console.log(this.options)
            if(!this.options) {
                return null;
            }
            for(let obj of this.options) {
                // console.log(`obj.jjCode: ${obj.jjCode}, this.value: ${this.value}`)
                if(obj.jjCode === this.value) {
                    return obj;
                }
            }
            return null;
        }
    },
    watch: {
        initValue(newVal) {
            if(!newVal) {
                this.value = '';
                return;
            }
            this.selectObj = newVal;
        }
    }
}
</script>
